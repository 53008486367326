/**
 * Reactive Mixin used by components that needs to be reactive
 * @type {{computed: {visibleOnMdAndUp(): reactiveMixin.computed.$vuetify.breakpoint.mdAndUp}}}
 */
export const reactiveMixin = {
  computed: {
    /**
     * Get breakpoint mdAndUp
     * @return {boolean}
     */
    visibleOnMdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    /**
     * Get breakpoint smAndUp
     * @returns {boolean}
     */
    visibleOnSmAndUp() {
      return this.$vuetify.breakpoint.smAndUp;
    },
    /**
     * Get breakpoint smAndDown
     * @returns {boolean}
     */
    visibleOnSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  }
};
