import { displayTypes, fontEmphasis, Text } from "@/design/text/Text";
import {
  Color,
  colorMD,
  variantNumber,
  variantType
} from "@/design/colors/Color";

/**
 * title Class
 * @param {string} color material design color
 * @param {boolean} bold
 * @return {string} text class
 */
const titleClass = (color = colorMD.grey, bold = true) =>
  new Text(
    new Color(color, variantType.darken, variantNumber.n1),
    displayTypes.subtitle1,
    bold ? fontEmphasis.bold : fontEmphasis.regular
  ).getClassText();

export { titleClass };
