<template>
  <v-expansion-panels focusable v-model="panel" :key="panelKey">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <project-banner :project="project"></project-banner>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <v-expansion-panels>
          <!-- project Timeline, Status & Calendar -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <v-row no-gutters>
                  <v-col cols="4">
                    <span :class="titleClass">
                      Status
                    </span>
                  </v-col>
                  <v-col cols="8">
                    <v-fade-transition leave-absolute>
                      <span v-if="!open">
                        <span :class="titleClass">
                          Timeline, Status, Calendar
                        </span>
                      </span>
                    </v-fade-transition>
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <project-chart
                :record="record"
                :project="projectFull"
              ></project-chart>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- project details -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span :class="titleClass">
                {{ projectDetailsHeader }}
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <form-project
                :project="projectFull"
                :record="record"
              ></form-project>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- project's user tasks -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span :class="titleClass">
                {{ tasksHeaderText }}
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <project-tasks :project="projectFull"></project-tasks>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- slot Project Actions -->
        <v-container fluid>
          <slot name="actions"></slot>
        </v-container>

        <!-- slot alert -->
        <slot name="alert"></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { reactiveMixin } from "@/mixins/shared/reactive/reactiveMixin";
import { titleClass } from "@/services/text/textService";

export default {
  name: "ProjectLayout",
  components: {
    ProjectBanner: () => import("@/components/project/ProjectBanner"),
    FormProject: () => import("@/components/project/FormProject"),
    ProjectTasks: () => import("@/views/projects/ProjectTasks"),
    ProjectChart: () => import("@/components/project/ProjectChart")
  },
  mixins: [reactiveMixin],
  props: {
    /**
     * base listed Project
     */
    project: undefined,
    /**
     * Full loaded project
     */
    projectFull: undefined,
    /**
     * project's record
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[]}}
     */
    record: undefined
  },
  data() {
    return {
      panel: undefined,
      panelKey: 1
    };
  },
  computed: {
    /**
     * Get Workflow Project Name
     * @return {String|string} Workflow Project Name
     */
    projectName() {
      return this.project?.projectName ?? "";
    },

    /**
     * user Tasks
     * @return {{taskId: number, typeId: number, actorId: number, actorName: string, userName: string, assignee: string, canReassign: boolean, canceledRecipients: string, comment: string, dueDate: string, duration: string, isDue: boolean, isMilestone: boolean, isOverdue: boolean, priority: number, recordId: number, recordName: string, status: number, statusText: string, notes: {id: number, created: string, taskId: number, userId: number, userName: string}[]}[]}
     */
    tasks() {
      return this.project?.userTasks ?? [];
    },

    /**
     * task Count
     * @return {number}
     */
    taskCount() {
      return this.tasks?.length ?? 0;
    },

    /**
     * project Charts Header
     * @return {string}
     */
    projectChartsHeader() {
      return `Charts (Timeline, Status, Calendar)`;
    },

    /**
     * project Details Header
     * @return {string}
     */
    projectDetailsHeader() {
      return `Project Details`;
    },

    /**
     * tasks Header Text
     * @return {string}
     */
    tasksHeaderText() {
      return this.taskCount > 1
        ? `(${this.taskCount}) Tasks`
        : `(${this.taskCount}) Task`;
    },

    /**
     * title Class
     * @return {string}
     */
    titleClass() {
      return titleClass();
    }
  },
  watch: {
    panel(newValue) {
      //This force re-renders the component attached to this key, necessary to load chart.
      this.panelKey += 1;
      this.$emit(newValue === 0 ? "expanded" : "collapsed");
    }
  }
};
</script>
